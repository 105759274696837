import { RJSFSchema, UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import JSONSchemaForm from "@rjsf/core";
import { EntryTimeContext } from "../contexts/EntryTimeContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import ProjectSelector from "@main/projects/components/ProjectSelector";
import widgets from "@beeldit/core/widgets/widgets";

interface Prop {
  formConfig: {
    formRef: any;
    onSubmit: any;
  };
}

function EntryTimeForm(props: Prop) {
  const { formRef, onSubmit } = props.formConfig;
  const { element, setElement, backendFormErrors } =
    useContext(EntryTimeContext);

  const { t } = useTranslation();

  const translations = {
    task: t("task"),
    minutes: t("minutes"),
    no_computed_minutes: t("no_computed_minutes"),
    status: t("status"),
    project_id: t("project_id"),
    customer_id: t("customer_id"),
    fee_id: t("fee_id"),
    date: t("date"),
    report_id: t("report_id"),
  };

  const schema: RJSFSchema = {
    type: "object",
    // required: ['minutes', 'customer_id', 'task', 'date', 'report_id'],
    properties: {
      task: { type: "string", title: translations.task },
      minutes: { type: "integer", title: translations.minutes, default: 0 },
      no_computed_minutes: {
        type: "integer",
        title: translations.no_computed_minutes,
        default: 0,
      },
      status_id: {
        type: "integer",
        title: translations.status,
        default: 0,
        oneOf: [
          {
            const: 0,
            title: "No notificado",
          },
          {
            const: 1,
            title: "Notificado",
          },
        ],
      },
      project_id: {
        type: "integer",
        title: translations.project_id,
      },
      customer_id: {
        type: "integer",
        title: translations.customer_id,
        default: 2,
        oneOf: [
          {
            const: 1,
            title: "Bloonde S.L",
          },
          {
            const: 2,
            title: "Ezzing solar",
          },
          {
            const: 3,
            title: "BigCaptain",
          },
        ],
      },
      fee_id: {
        type: "integer",
        title: translations.fee_id,
        default: 4,
        oneOf: [
          {
            const: 1,
            title: "Análisis y desarrollo - Bloonde S.L",
          },
          {
            const: 2,
            title: "Análisis y desarrollo reducido - Bloonde S.L",
          },
          {
            const: 3,
            title: "Análisis y desarrollo coste cero - Bloonde S.L",
          },
          {
            const: 4,
            title: "Análisis y desarrollo - Ezzing solar",
          },
          {
            const: 5,
            title: "Análisis y desarrollo - BigCaptain",
          },
        ],
      },
      date: {
        type: "string",
        title: translations.date,
        format: "date",
        default: new Date().toISOString().slice(0, 10),
      },
      report_id: {
        type: "integer",
        title: translations.report_id,
        default: 1,
        oneOf: [
          {
            const: 21,
            title: "Diciembre de 2024",
          },          
          {
            const: 22,
            title: "Enero de 2025",
          },
          {
            const: 23,
            title: "Febrero de 2025",
          },
          {
            const: 24,
            title: "Marzo de 2025",
          },
          {
            const: 25,
            title: "Abril de 2025",
          },
          {
            const: 26,
            title: "Mayo de 2025",
          },
          {
            const: 27,
            title: "Junio de 2025",
          },
          {
            const: 28,
            title: "Julio de 2025",
          },
          {
            const: 29,
            title: "Agosto de 2025",
          },
          {
            const: 30,
            title: "Septiembre de 2025",
          },
          {
            const: 31,
            title: "Octubre de 2025",
          },
          {
            const: 32,
            title: "Noviembre de 2025",
          },
          {
            const: 33,
            title: "Diciembre de 2025",
          }

        ],
      },
    },
  };
  const uiSchema: UiSchema = {
    "ui:submitButtonOptions": {
      props: {
        disabled: false,
        className: "btn btn-info",
      },
      norender: true,
    },
    project_id: {
      "ui:widget": "project-selector",
    },
  };

  const customWidgets = {
    ...widgets,
    ...{
      "project-selector": ProjectSelector,
    },
  };
  const handleChange = (type: any) => {
    /** Este método de momento es necesario aunque en realidad no tenga sentido, pero se debe a un bug de la libreria */
    // Actualizar el estado solo si no hay errores de validación
    setElement(type.formData);
    return console.log.bind(console, type);
  };
  const log = (type: any) => {
    return console.log.bind(console, type);
  };

  return (
    <JSONSchemaForm
      noHtml5Validate
      showErrorList={false}
      ref={formRef}
      schema={schema}
      uiSchema={uiSchema}
      widgets={customWidgets}
      formData={element}
      validator={validator}
      onChange={handleChange}
      onSubmit={onSubmit}
      onError={log("errors")}
      extraErrors={backendFormErrors}
    />
  );
}

export default EntryTimeForm;
